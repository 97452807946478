import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Heading from '../components/Heading'
import Text from '../components/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const PageTermsUseEssential = (): JSX.Element => (
  <Layout pageType="informacoes-essenciais-termos-de-uso" toggle={() => {}} isWebView={true}>
    <Container className='container'>
      <div>
        <Heading headingLevel='h1' size='medium'>
          Prospecto das Informações Essenciais dos Termos e Condições Gerais de Uso
        </Heading>

        <Text>
          Aqui Sua Empresa terá acesso a um resumo dos Termos e Condições Gerais de Uso da Sua Conta Cora. Nosso objetivo é, em observância ao disposto na Resolução BCB nº 96, de 19 de maio de 2021  te explicar sobre as características da Conta Cora e suas regras básicas de funcionamento, formas disponíveis de movimentação de recursos, formas de acesso para consulta a tarifas e seus respectivos procedimentos de cobrança, prazos para fornecimento de comprovantes e outros documentos, bem como medidas de segurança para fins de movimentação e utilização, inclusivo em caso de perda, furto ou roubo de credenciais de acesso à a Sua Conta Cora.<br/>
          <br/>
          Lembramos que este resumo não isenta a necessidade de leitura do Termo como um todo.<br/>
          <br/>
        </Text>

        <Heading headingLevel='h3' size='small'>
          O que é a Conta CORA
        </Heading>

        <Text>
          A Conta CORA é uma conta de pagamento digital pré-paga destinada à realização de transações de pagamento. Sua Empresa poderá acessá-la e transferir recursos financeiros para ela e dela para outras contas, a partir do pagamento de boleto, transferências bancárias, Pix ou por outras formas que venham a ser disponibilizadas pela CORA.<br/>
          <br/>
        </Text>

        <Heading headingLevel='h3' size='small'>
          Funcionalidades da Conta Cora
        </Heading>

        <Text>
          Cartão: Compreende-se como Cartão toda modalidade emitida pela Cora, seja pré-pago ou pós-pago, físico ou virtual, que opera na função débito e na função crédito, a ser recebido por sua Empresa, conforme solicitação - para a função crédito, após análise e aprovação de crédito, conforme Termos de Uso do Cartão de Crédito Cora.<br/>
          <br/>
          Compras em Estabelecimentos: o Cartão permite movimentar a Conta CORA e realizar a compra de bens ou serviços em Estabelecimentos que aceitem pagamentos em cartões da Bandeira;<br/>
          <br/>
          Saque: o Cartão permite movimentar a Conta CORA, de forma a retirar recursos em Caixas eletrônico 24 (vinte e quatro) Horas. Para a realização de saques do Cartão de Débito será cobrada a tarifa de R$ 9,90 (nove reais e noventa centavos) por saque. A cobrança será realizada via débito em conta no momento em que o saque for efetuado.<br/>
          <br/>
          Transferências entre Contas: Sua Empresa poderá transferir recursos financeiros, observados os prazos e procedimentos previstos pelo sistema financeiro brasileiro, da sua Conta CORA para Contas CORA, contas bancárias e outras contas de pagamentos (contas digitais);<br/>
          <br/>
          Pagamento de Boletos e Contas de Consumo: Sua Empresa poderá realizar pagamentos de boletos e contas de consumo de concessionárias conveniadas, sempre sujeito às limitações impostas pela legislação brasileira e pelas empresas emissoras dos respectivos títulos/boletos;<br/>
          <br/>
          Pix: Transações do Pix: Uma transação do Pix pode ser enviada das seguintes maneiras:<br/>
          I) Inserção manual dos detalhes da conta do usuário recebedor;<br/>
          II) Leitura de QR code;<br/>
          III) Copiar e colar um código do Pix;<br/>
          IV) Utilização de uma Chave Pix.<br/>
          <br/>
          Emissão de Boletos: Sua Empresa poderá emitir boletos, cujo pagamento direcionará os recursos diretamente para sua Conta CORA; Após o 100º boleto compensado no mês, Sua Empresa terá a cobrança da tarifa de R$ 1,90 (um real e noventa centavos) por boleto compensado, a ser descontada diretamente da sua Conta CORA quando da efetiva compensação do boleto.<br/>
          <br/>
        </Text>

        <Heading headingLevel='h3' size='small'>
          Do Uso Correto da Sua Conta CORA
        </Heading>

        <Text>
          A CORA poderá impor limitações ao uso, temporária ou definitivamente, bem como excluir a Conta CORA de um Usuário e realizar a extração dos valores obtidos indevidamente, a qualquer momento, além de tomar as medidas legais cabíveis se: (1) o Usuário não cumprir qualquer dispositivo deste Termos de Uso; (2) praticar atos fraudulentos, dolosos ou com evidente má-fé, a fim de obter vantagem indevida, incluindo ainda a obtenção de cashback por outras plataformas digitais; (3) se o Usuário não enviar informações e documentos solicitados para a verificação de segurança dentro do prazo estipulado; (4) identificar atos do Usuário realizados com a finalidade de induzir ao erro para obter vantagem manifestamente ilícita; (5) não puder ser verificada a identidade do Usuário ou qualquer informação fornecida por ele seja inverídica; e (6) alguma atitude do Usuário tiver o condão de causar danos a terceiros ou à CORA.<br/>
          <br/>
        </Text>

        <Heading headingLevel='h3' size='small'>
          Como você pode contribuir para a Segurança da Sua Conta CORA
        </Heading>

        <Text>
          Segurança do Cartão: Recomenda-se que: (I) o Cartão seja guardado em local seguro, jamais permitindo seu uso por terceiros; (II) sua senha seja memorizada e mantida em sigilo, evitando anotar ou guardar a senha em suportes físicos ou digitais; (III) sua senha nunca seja anotada ou guardada em conjunto com o Cartão; e (IV) sua senha seja forte, não relacionada a datas ou outras referências pessoais de Sua Empresa (Você ou outro Usuário, conforme o caso), e seja trocada periodicamente.<br/>
          Segurança da Conta: Em caso de perda, extravio, furto ou roubo do login ou Senha para acesso à Conta CORA de Sua Empresa, ou do Cartão de Débito CORA, Você deve, imediatamente:<br/>
          <br/>
          (a) Redefinir a Senha e/ou realizar o bloqueio do Cartão; ou<br/>
          (b) Diante da impossibilidade em proceder conforme indicado no item acima, entrar em contato conosco, por meio do telefone indicado dentre os Canais de Comunicação ou chat, e solicitar a redefinição da Senha e/ou o bloqueio do Cartão.<br/>
          <br/>
          Segurança do Acesso do Aplicativo: Como medida de segurança, caso decida utilizar outro smartphone que não aquele no qual previamente você tenha feito download do aplicativo, a CORA poderá solicitar a confirmação algumas informações com a finalidade de garantir a sua segurança. Caso a CORA entenda que não houve o atendimento das informações de forma correta a garantir sua identidade, poderá não autorizar a utilização da sua Conta CORA.<br/>
          <br/>
          Segurança do PIX: As transações do Pix estão sujeitas à análise de risco e exigem que a conta Cora do usuário pagador tenha saldo disponível suficiente para tal transação. Caso o usuário pagador não tenha saldo disponível suficiente na conta, a transação do Pix não será realizada. A CORA poderá definir limites para as transações do Pix e rejeitá-las, na medida permitida pela lei aplicável.
        </Text>
      </div>
    </Container>
  </Layout>
)

export default PageTermsUseEssential
